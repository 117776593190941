// Tenancies
export const GET_TENANCIES = 'GET_TENANCIES';
export const ADD_TENANCY = 'ADD_TENANCY';
export const UPDATE_TENANCY = 'UPDATE_TENANCY';
export const DELETE_TENANCY = 'DELETE_TENANCY';
export const TENANCIES_LOADING = 'TENANCIES_LOADING';

// Users
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const SIGNUP_REQUEST_SUCCESS = 'SIGNUP_REQUEST_SUCCESS';
export const SIGNUP_REQUEST_FAIL = 'SIGNUP_REQUEST_FAIL';

// Billing
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_LOADED = 'CUSTOMER_LOADED';
export const CUSTOMER_LOADING_FAILED = 'CUSTOMER_LOADING_FAILED';
export const INVOICES_LOADING = 'INVOICES_LOADING';
export const INVOICES_LOADED = 'INVOICES_LOADED';
export const INVOICES_LOADING_FAILED = 'INVOICES_LOADING_FAILED';
export const CLEAR_INVOICES = 'CLEAR_INVOICES';

// Errors
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// Admin
export const ADMIN_USERS_LOADING = 'ADMIN_USERS_LOADING';
export const ADMIN_USERS_LOADED = 'ADMIN_USERS_LOADED';
export const ADMIN_CREATE_USER = 'ADMIN_CREATE_USER';
export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER';
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';
export const ADMIN_INVOICES_LOADING = 'ADMIN_INVOICES_LOADING';
export const ADMIN_INVOICES_LOADED = 'ADMIN_INVOICES_LOADED';
export const ADMIN_INVOICES_LOADING_FAILED = 'ADMIN_INVOICES_LOADING_FAILED';
export const ADMIN_CLEAR_INVOICES = 'ADMIN_CLEAR_INVOICES';
export const ADMIN_XERO_CONTACTS_LOADING = 'ADMIN_XERO_CONTACTS_LOADING';
export const ADMIN_XERO_CONTACTS_LOADED = 'ADMIN_XERO_CONTACTS_LOADED';
export const ADMIN_CUSTOMER_LOADED = 'ADMIN_CUSTOMER_LOADED';
export const ADMIN_CUSTOMER_LOADING = 'ADMIN_CUSTOMER_LOADING';
export const ADMIN_CUSTOMER_LOADING_FAILED = 'ADMIN_CUSTOMER_LOADING_FAILED';
export const ADMIN_TENANCIES_LOADED = 'ADMIN_TENANCIES_LOADED';
export const ADMIN_TENANCIES_LOADING = 'ADMIN_TENANCIES_LOADING';
export const ADMIN_UPDATE_TENANCY = 'ADMIN_UPDATE_TENANCY';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL';
export const ADMIN_LOGOUT_SUCCESS = 'ADMIN_LOGOUT_SUCCESS';
export const ADMIN_AUTH_ERROR = 'ADMIN_AUTH_ERROR';
export const ADMIN_LOADING = 'ADMIN_LOADING';
export const ADMIN_LOADED = 'ADMIN_LOADED';
