import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import SignupRequestForm from './SignupRequestForm';
import { SignUpRequestSchema } from '../account/accountSchema';
import { sendSignUpRequest } from '../../../../actions/authActions';

function SignupRequestFormWrapper(props) {

  const handleSubmit = (fields, { setSubmitting, setErrors, setStatus }) => {
    setStatus({});
    props
      .sendSignUpRequest(fields)
      .then(() => {
        setStatus({ success: true });
      })
      .catch((err) => {
        setErrors(err);
        setStatus({ success: false });
      })
      .finally(() => {
        setSubmitting( false );
      });
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    company: '',
    physicalAddress: '',
    accountName: '',
    accountSurname: '',
    amountSpots: '',
    registrationNumber: '',
  };

  return (
    <div className="form-wrapper">
      <div className="heading">
        <h4>Sign up request</h4>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={SignUpRequestSchema}
        onSubmit={handleSubmit}
        render={SignupRequestForm}
      />
    </div>
  );
}

SignupRequestFormWrapper.propTypes = {
  sendSignUpRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

const mapDispatchToProps = {
  sendSignUpRequest,
};

export default connect(
  null,
  mapDispatchToProps,
)(SignupRequestFormWrapper);
