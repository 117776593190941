import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form as FormikForm } from 'formik';
import { Form, Button, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import FormField from '../../forms/FormField';

function isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export default function SignupRequestForm({ isSubmitting, status = {} }) {

  const showAlertSuccess = ( !isEmpty(status) && status.success );

  return (

    <FormikForm>
      <p>Contact</p>
      <Form.Row>
        <Form.Group as={Col}>
          <FormField name="firstName" placeholder="First Name" autoComplete="given-name" />
        </Form.Group>
        <Form.Group as={Col}>
          <FormField name="lastName" placeholder="Last Name" autoComplete="family-name" />
        </Form.Group>
      </Form.Row>
      <Form.Group>
        <FormField name="phoneNumber" placeholder="Phone number" autoComplete="phone-number" />
      </Form.Group>
      <Form.Group>
        <FormField name="email" type="email" autoComplete="email username" placeholder="Email" />
      </Form.Group>

      <p>Company</p>
      <Form.Group>
        <FormField name="company" placeholder="Company name" autoComplete="organization" />
      </Form.Group>
      <Form.Group>
        <FormField name="physicalAddress" placeholder="Physical Address" autoComplete="physical-address" />
      </Form.Group>
      <Form.Row>
        <Form.Group as={Col}>
          <FormField name="accountName" placeholder="Account Name" autoComplete="account-name" />
        </Form.Group>
        <Form.Group as={Col}>
          <FormField name="accountSurname" placeholder="Account Surname" autoComplete="account-surname" />
        </Form.Group>
      </Form.Row>

      <p>Parking spots</p>
      <Form.Row>
        <Form.Group as={Col}>
          <FormField name="amountSpots" placeholder="Amount of spots" autoComplete="amount-spots" />
        </Form.Group>
        <Form.Group as={Col}></Form.Group>
      </Form.Row>
      <Form.Group>
        <FormField name="registrationNumber" placeholder="Registration number(s)" autoComplete="registration-number" component="textarea" rows="2" />
      </Form.Group>

      <Alert variant="success" show={showAlertSuccess}>
        Thank you. The request has been sent. <br />
        Shortly, we'll contact you to discuss details.
      </Alert>

      <p className="terms">
        Signing up for an Innovation Carpark account means you agree to the <Link to="/terms">Terms of Service.</Link>
      </p>

      <Form.Row className="actions-row">
        <Col>
          <Button variant="jumbo" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Sending request...' : 'Submit'}
            <FontAwesomeIcon icon="chevron-right" className="ml-2" />
          </Button>
        </Col>
      </Form.Row>
    </FormikForm>
  );
}

SignupRequestForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  status: PropTypes.shape({}),
};
SignupRequestForm.defaultProps = {
    status: {},
};
