import * as Yup from 'yup';

const passwordSchema = Yup.string()
  .required('Please enter a password')
  .min(6, 'Password must be at least 6 characters long')
  .max(256, 'Maximum length is 256 characters');

const emailSchema = Yup.string()
  .lowercase()
  .required('Please enter your email')
  .email('Invalid email')
  .max(256, 'Maximum length is 256 characters');

const firstNameSchema = Yup.string()
    .required('Please enter the first name')
    .max(256, 'Maximum length is 256 characters');

const lastNameSchema = Yup.string()
    .required('Please enter the last name')
    .max(256, 'Maximum length is 256 characters');

const companySchema = Yup.string()
    .max(256, 'Maximum length is 256 characters');


export const updatePasswordSchema = Yup.object().shape({
  currentPassword: passwordSchema,
  newPassword: passwordSchema,
});

export const forgotPasswordSchema = Yup.object().shape({
  email: emailSchema,
});

export const authSchema = Yup.object().shape({
  email: emailSchema,
  password: passwordSchema,
});

export const updateUserSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  company: companySchema,
});

// Extends the updateUserSchema to include password
export const createUserSchema = updateUserSchema.shape({
  password: passwordSchema,
});

export const resetPasswordSchema = authSchema;

const isValidPhoneNumber = (phoneStr) => {
  if ( phoneStr === undefined ) { return null; }

  const phoneNumber = phoneStr.replace(/[\\+\\(\\)\s\\-]/g, '');

  const mobilePhonesRegExp = /^(64)?(0)?2[125789]\d{6,8}$/;
  const landLinePhonesRegExp = /^(0[34679])\d{7}$/;
  const specPhoneRegExp = /^(0508|0800|0900)\d{5,8}$/;

  return ( mobilePhonesRegExp.test(phoneNumber) || landLinePhonesRegExp.test(phoneNumber) || specPhoneRegExp.test(phoneNumber) );
};

export const SignUpRequestSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  phoneNumber: Yup.string()
    .required('Please, enter an Phone number')
    .max(20, 'Maximum length is 20 characters')
    .test('is-valid-phone-number', 'Phone number is not valid', isValidPhoneNumber),
  email: emailSchema,
  company: companySchema,
  physicalAddress: Yup.string()
    .required('Please, enter the Address')
    .max(256, 'Maximum length is 256 characters'),
  accountName: Yup.string().max(256, 'Maximum length is 256 characters'),
  accountSurname: Yup.string().max(256, 'Maximum length is 256 characters'),
  amountSpots: Yup.string()
    .required('Please, enter number of the spots')
    .max(5, 'Maximum length is 5 characters')
    .matches(/^\d+$/, 'Number is not valid'),
  registrationNumber: Yup.string()
    .required('Please, enter the License Plate Number(s)')
    .max(256, 'Maximum length is 256 characters'),
});
